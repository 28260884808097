import { selectDrawerQueryParams } from 'ducks/drawers/selectors'
import { selectDefaultSortOrderByQueryId } from 'ducks/searches/selectors/selectDefaultSortOrderByQueryId'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { doOpenSearchPayload } from './doOpenSearchPayload'

export const doOpenSearchPage = (term, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const currentQuery = selectDrawerQueryParams(state)
  const defaultOrderBy = selectDefaultSortOrderByQueryId(state, term)
  const classicView = selectPrefersClassicView(state)
  const ticketId = classicView ? null : selectCurrentTicketId(state)
  return dispatch(
    doOpenSearchPayload(ticketId, term, currentQuery, {
      ...options,
      defaultOrderBy,
    })
  )
}
