// Groove JSON API
import Bugsnag from '@bugsnag/js'
import { toQueryString } from 'util/params'
import { createQueue, queueify } from 'util/queue'
import { getTabId } from 'util/tabId'
import { getVersion } from 'util/version'
import { v4 as uuidV4 } from 'uuid'
import storage from 'util/storage'

const reportToBugsnag = (method, err, path, requestId) => {
  const escapedPath = path
    .split('?')
    .shift()
    .replace(/\/\d+/g, '/?')
  const escapedPathGroupped = escapedPath.replace(
    /(api\/settings\/canned_replies\/).*/g,
    '$1:canned_reply_id'
  ) // temp fix for canned replies
  const requestType = [method, escapedPathGroupped].join(' ')
  Bugsnag.notify(new Error(`API ${requestType} call failed`), event => {
    // eslint-disable-next-line no-param-reassign
    event.groupingHash = requestType
    // eslint-disable-next-line no-param-reassign
    event.severity = 'error'

    event.addMetadata('metaData', {
      request: {
        requestId,
      },
      errorDetails: {
        errorJson: err.toJson ? err.toJson() : null,
        errorRaw: err,
        errorClass: err.name,
        errorMessage: err.message,
        stack: err.stack,
        requestType,
        path,
      },
    })
  })
}

export class GrooveAPI {
  constructor({ apiUrl, concurrency, withCredentials = true }) {
    this.apiUrl = apiUrl
    this.queueId = createQueue(concurrency)
    this.withCredentials = withCredentials
    this.get = queueify(this.queueId, this.get.bind(this))
    this.send = queueify(this.queueId, this.send.bind(this))
  }

  get(token, path, params, headers) {
    return new Promise((resolve, reject) => {
      const query = toQueryString(params)
      const parts = [this.apiUrl, '/', path]

      if (query !== '') parts.push(`?${query}`)

      const url = parts.join('')
      const request = new XMLHttpRequest()
      const requestId = uuidV4()

      request.open('GET', url, true)
      request.setRequestHeader('Content-Type', 'application/json')
      request.setRequestHeader('Authorization', `Bearer ${token}`)
      request.withCredentials = this.withCredentials
      request.setRequestHeader('X-Client-Tab-Id', getTabId())
      request.setRequestHeader('X-Client-App-Version', getVersion())
      request.setRequestHeader('X-Request-Id', requestId)
      if (headers) {
        Object.keys(headers).forEach(key => {
          request.setRequestHeader(key, headers[key])
        })
      }
      request.onload = () => {
        const text = request.responseText
        let json = null
        try {
          json = JSON.parse(text)
          resolve({
            code: request.status,
            body: text,
            json,
          })
        } catch (err) {
          reject({
            code: request.status,
            body: text,
            json,
          })
        }
      }
      request.onerror = err => {
        console.error(err) // eslint-disable-line

        let shouldReport = true
        if (url && url.match('metrics.json')) shouldReport = false
        if (shouldReport) reportToBugsnag('GET', err, path, requestId)

        const betterError = new TypeError('Network request failed')
        reject(betterError)
      }
      request.send(null)
    })
  }

  async send(
    method,
    _token,
    path,
    params,
    body = null,
    headers = {},
    options = {}
  ) {
    const { token } = storage.get('auth') || {}
    const requestId = options.requestId || uuidV4()
    const newHeaders = {
      ...headers,
      'X-Client-Tab-Id': getTabId(),
      'X-Client-App-Version': getVersion(),
      'X-Request-Id': requestId,
    }
    if (!options.skipContentTypeHeader) {
      newHeaders['Content-Type'] = 'application/json'
    }

    if (token) newHeaders.Authorization = `Bearer ${token}`
    const query = toQueryString(params)
    const parts = [this.apiUrl, '/', path]
    if (query !== '') parts.push(`?${query}`)

    const url = parts.join('')

    const requestArgs = {
      method,
      redirect: 'follow',
      mode: 'cors',
      headers: new Headers(newHeaders),
      credentials: this.withCredentials ? 'include' : 'omit',
    }
    // GET/HEAD requests blow up if we add body, even a null one.
    const getOrHead = ['GET', 'HEAD'].includes(method)
    const includeBody = !getOrHead
    if (includeBody) requestArgs.body = body

    const request = new Request(url, requestArgs)

    try {
      const response = await fetch(request)
      let json
      if (!options.skipParseResponse) {
        try {
          json = await response.json()
        } catch (err) {
          json = null
        }
      }

      let responseText
      if (!response.bodyUsed) {
        responseText = await response.text()
      }

      return {
        code: response.status,
        body: responseText,
        json,
        success: response.ok,
      }
    } catch (err) {
      console.error(err) // eslint-disable-line

      let shouldReport = true
      if (url && url.match('metrics.json')) shouldReport = false
      if (shouldReport) reportToBugsnag(method, err, path, requestId)

      const betterError = new TypeError('Network request failed')
      return betterError
    }
  }

  put(token, path, params, body, headers, options) {
    return this.send('PUT', token, path, params, body, headers, options)
  }

  post(token, path, params, body, headers, options) {
    return this.send('POST', token, path, params, body, headers, options)
  }

  graphql(token, path, query, variables) {
    return this.post(
      token,
      path,
      null,
      JSON.stringify({ query, variables })
    ).then(({ json }) => json)
  }

  delete(token, path, params) {
    return this.send('DELETE', token, path, params)
  }
}

export default (apiUrl, apiConcurrency) =>
  new GrooveAPI({ apiUrl, concurrency: apiConcurrency })
